import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`文章を書くために、MindNodeというアプリを使うことが多いのでその紹介をします。`}</p>
      <p>{`自分はなんだかんだで仕事をしているうちの結構な割合を文章を書くことに費やしている。そういうのをやっているうち、何かしら文章を書くとき、特にその文章がある程度の長さである場合、自分の場合はまずMindNodeで内容を整理したりして「下書き」的なものを作った後、文章を書き出すと大体スムーズに進む感じがしている。その過程でMindNodeを使うことで何が嬉しいのか？というのを振り返りつつ書いた。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "mindnodeって何"
      }}>{`MindNodeって何`}</h2>
      <p>{`この、Mac／iPhone／iPadで使えるアプリです。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.mindnode.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`MindNode`}</a></li>
      </ul>
      <p>{`世間的には「マインドマップ」として認識されているようなモノを作れます。`}</p>
      <p>{`iPadだけ持ってカフェで書くとかもアリっぽい気はしますが、自分の場合はだいたい何かしら調べつつ書いたりするので、だいたいMacで使ってる。`}</p>
      <h2 {...{
        "id": "mindnodeとワタシ"
      }}>{`MindNodeとワタシ`}</h2>
      <p>{`MindNodeはサブスクリプション型のソフトなんですが、なんだかんだ2年以上契約している。`}</p>
      <p>{`どういう時に使っているかと言うと、業務内だと`}<a parentName="p" {...{
          "href": "https://www.codegrid.net/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`CodeGrid`}</a>{`の記事を結構書くほか、作ったWebアプリだったりの仕様を文章にまとめたりすることも結構多い。あとは本書いたりとか、こういう記事を書いたりとかそういう時、概ね7割ほどは文章を書く前にMindNodeで下書き的なものを作っている。`}</p>
      <p>{`このMindNodeで作ったものは一体どういうものなのかと言うと、以下みたいなやつです。`}</p>
      <p>{`まずこの記事自体を書くために作ったやつが以下。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.dropbox.com/sh/l8dn38h5c2rj2kv/AADptUzH2hAZct0d7OXVsCZFa/mindnode.pdf?dl=0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`この記事のやつ`}</a></li>
      </ul>
      <p>{`この前`}<a parentName="p" {...{
          "href": "https://takazudo.me/notes/2022-05-28-good-notes/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`GoodNotesについて紹介した記事`}</a>{`のやつが以下。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.dropbox.com/sh/l8dn38h5c2rj2kv/AAC7_GFZ4nrhUYA865qAV9lFa/good-notes.pdf?dl=0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`GoodNotesの記事のやつ`}</a></li>
      </ul>
      <p>{`CodeGridのやつだと、例えば`}<a parentName="p" {...{
          "href": "https://www.codegrid.net/series/2021-headless-ui",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Headless UIの記事`}</a>{`のやつだと以下。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.dropbox.com/sh/l8dn38h5c2rj2kv/AAD16tyN6MR6yP3N2uFta6bua/headless-ui.pdf?dl=0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Headless UIの記事のやつ`}</a></li>
      </ul>
      <p>{`ほか、これは超大作みたいになってるんですが、`}<a parentName="p" {...{
          "href": "https://amzn.to/3xYmx6g",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ざっくりつかむ CSS設計`}</a>{`を書く時に作ったやつが以下。これは2つあって、1つがとりあえずアイデア整理用、2つ目が原稿になる手前の段階のやつ。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.dropbox.com/sh/l8dn38h5c2rj2kv/AAD2Mo2kDgPPPTxUKEREVzOXa/css-bon1.pdf?dl=0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`アイデア整理用のやつ`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.dropbox.com/sh/l8dn38h5c2rj2kv/AACv4cBCRt5yBxc3_dC3zNkSa/css-bon2.pdf?dl=0",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`原稿の手前段階のやつ`}</a></li>
      </ul>
      <h2 {...{
        "id": "mindnodeが無いとどうなるのか"
      }}>{`MindNodeが無いとどうなるのか`}</h2>
      <p>{`自分の場合、MindNodeは、意識して使うようにしているわけではなくて、結果的になんかずっと使ってますねというのが感覚としてあるのだけれど、なぜこれを使っているのか？`}</p>
      <p>{`自分を含め多くの人は、普段、仕事なりで文章を書くことがそれなりにあるかと思うんですが、それなりの頻度で文章を書いていると、これって結構難しいことなのかもなーと思うことがある。特にある程度以上の長さの文章になるとこれが顕著で、とりあえず書いていきさえすれば、文章という形になりはするものの、他の人が見て読みやすい、内容が伝わりやすい文章というものに仕上げるには、経験や技術が必要になるところだなーと。`}</p>
      <p>{`例えば「見出し」や「箇条書き」というものがあって、それは我々は普段文章を書く上でなんとなく使ってはいるものの、他の人が読んでも読みやすいようにうまいこと使えるかと言うと、そこには技術や配慮みたいなものが必要になるなーと感じる。この「見出し」や「箇条書き」というものは、言ってみればこれはカナズチやノコギリみたいな道具的なものであって、何も知らない人が使ってうまいこと形になるかと言われると、そういうわけでもない。`}</p>
      <p>{`一気に原稿を書き終えてみたら、大見出しが4つになっていて、その1つ目の大見出しの内容が文章量の約半分を締めてしまったり、その大見出しの中にだけやたら深い構造の見出しがあったりとか。なんとなく文章を書いていると、フツーにそういうことが起こってしまう。`}</p>
      <p>{`自分は、こういった問題を、MindNodeを使って予め文章になる前の状態で整理することによって、だいぶ回避できている気がする。`}</p>
      <h2 {...{
        "id": "なぜ偏った構成の文章になってしまうのか"
      }}>{`なぜ偏った構成の文章になってしまうのか`}</h2>
      <p>{`じゃあなんでそういう偏った構成の文章になってしまいがちなのか？と考えたが、これは、多くの人にとっては、文章を書きながら全体も同時に把握するの難しいからなのかもしれない。`}</p>
      <p>{`例えば絵を書くとき、多くの人はキャンバスに絵の具を垂らす前に、パレットに絵の具を出し、使いたい色をそこで作ってからキャンバスへと筆をすすめる。あとは下書きをまずは書いてみるとか。`}</p>
      <p>{`新しいレシピで料理を作ろうとするとき、いきなりフライパンに醤油や砂糖を入れるわけではなく、タッパなどで調味料を混ぜ、味見をしてからフライパンに投入したり。`}</p>
      <p>{`これらは別に飛ばしても良いステップだが、経験無くこれを行えば、望んだ結果にならなくなる可能性は高まる。文章もこれと同じようなことが言えるんじゃないだろーか。`}</p>
      <h2 {...{
        "id": "気になるポイント"
      }}>{`気になるポイント`}</h2>
      <p>{`具体的にどういうことがあるのか。自分がパッと思いついたのは「接続詞が気になりすぎてしまう」ということだった。「また」「しかし」「ほか」「ちなみに」とかそういうやつが。`}</p>
      <p>{`例えば何かしら文章を書くとして、それはブログなりQiitaやらZennの記事でも、ゲームのレビューでもなんでもいいのだけれども、そこで頭に思い浮かんだことをひたすら書くとどうなるか。この場合、なんか箇条書きの文がひたすら並んでいるような形になってしまうことがあり得る。そういうのは、読んでいれば内容は理解できるものの、スラスラ読みやすい文章になっているかと言われると微妙なところ。`}</p>
      <p>{`じゃあどうするかと言うと、文と文の間には、文同士をつなげる糊のような役割をする接続詞を挟むなどしていく。そうやって文章を書いていくと、最終的にはいい感じの出来上がりになることもありはするが、そのような形で文章を書いた場合、文章を書くというステップの中で、文章の内容とは別に、どういう接続詞にするか、「しかし」じゃなくて「しかしながら」の方がいいか？ここっていきなり話変わってないか？などと考える必要が出てくる。これが結構時間がかかるポイントなんじゃないかと自分は考えた。`}</p>
      <p>{`文章の構成的なところで言うと、段落のまとまりをどうするかとか、さっき道具だと言った、見出しにするか箇条書きにするかとか、そういうのも気になりだす。`}</p>
      <p>{`ほか、細かい言い回しや表記も気になってしまう。「〜です。／〜ます。」で文を終わらせるのか「〜だ。」にするのかとか。「読者の皆様はどう感じるだろうか」「読者の皆様はどう思われますか？」がいいのか。？は全角がいいのか半角がいいのかとか、気にしだしたらきりがない。`}</p>
      <p>{`とりあえず、「今書いているのは下書きなんです」という意識を強く持っていたとすれば、そういうのは無視しても構わないのだけれど、文章という体裁になっている以上、そういう点が自分は気になってしまう。なので、そこまで突っ込んでそのあたりが整備されておらずとも、ある程度そういう要素も加味して書いてしまった文章は、後から変更するのにエネルギーを要すると感じる。`}</p>
      <p>{`「やっぱりこの一文は最初で説明しないとい分かりづらいなー」みたいのが、文章を書き進めていった末に初めて気づくということがある。そういうのを最初に持っていったら、その中登場する単語についての補足をそこで行わないと分からんとか、その後の文章で説明していたことがこの最初にもってきた部分に含まれていると、他の部分も調節したりとか、そういう色々な要素があって、「あーー文書書くの大変だわ」ってなる。`}</p>
      <p>{`そして、そうやって書き終えた文章で、「やっぱりこの内容イマイチじゃない？これなら書かなくて良くない？」と気づき、頑張って整理したセクションをまるごと消したりなどするわけだ。`}</p>
      <h2 {...{
        "id": "mindnodeがあると何が良いのか"
      }}>{`MindNodeがあると何が良いのか`}</h2>
      <p>{`で、MindNodeで初めに下書き的なもの、文章になる前の段階の状態を一通り作り上げると、そこではここまでに挙げたような要素を排除して考える事ができると自分は感じる。`}</p>
      <p>{`例えば`}<a parentName="p" {...{
          "href": "https://amzn.to/3xYmx6g",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ざっくりつかむ CSS設計`}</a>{`にて、Sassについて書いた部分がある。その部分はMindNodeのファイルでは以下みたいにしていた。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/eaceaedb-15df-43b7-a7ec-912d0115bab8_sass.png?auto=compress,format&w=1228&h=1098",
          "alt": "MindNodeのファイルでSassについて書いた部分"
        }}></img></p>
      <ul>
        <li parentName="ul">{`Sassとは`}</li>
        <li parentName="ul">{`Sassってこんなやつ`}</li>
        <li parentName="ul">{`どうやって使う？`}</li>
        <li parentName="ul">{`Sass使うべき？`}</li>
      </ul>
      <p>{`という大きな括りになっている。`}</p>
      <p>{`ここで例えば最初の見出しに相当する「Sassとは」という部分について考えてみた場合、いきなり文章を書き始めた場合、思いつくままにSassについて語ろうとすれば、ここでSassの歴史だとか、Sassのmixinとかextendとか、一つ一つの機能についての解説を初めてしまうかもしれない。`}</p>
      <p>{`これが別に悪いことであるとは思わない。人との会話であれば、話の途中で昨日見たドラマの話で盛り上がり、そのあとはその話で持ちきりなんてことはよくあると思う。文章を書くこともそれと同じで、あるとピックについて書いていたら、連鎖的に色々と考えが巡ってきて、一気に書いてしまうということはある。`}</p>
      <p>{`ただ、この場合は、テーマとしてはCSS設計の本なので、Sassについてそういう話を突っ込んでする必要性が薄い。それを書いていったら、Sassの本になってしまうではないかという。`}</p>
      <p>{`MindNodeでまずはアイデアを整理していると、全体像をいつでも俯瞰することが出来る。あーここって今はビルドしてCSSを作るということについて、色々紹介しているところであることが認識できれば、ここで細かくSassの機能紹介をするべきではないことに気づきやすいと言うか。`}</p>
      <p>{`あとは、MindNodeを使っていて、ここで別にSassの話を細かく書いてしまってもよい。そして、ここではその話を今すべきじゃないから、別に「SassとCSS設計」みたいな章立てをして、そっちにノードを移動させるとか。ひとたび文章にしてしまえば、なるほどそれなら見出しレベルを一つ上げて、さっき書いたように、接続詞やら補足やらも変えて…… という必要が出てきて、めんどくさいなーってなる。自分の場合は。`}</p>
      <p>{`そんな風に、ノードの数や下層のボリュームにより、最終的な文章量がある程度把握できるので、文章になる前で大まかな構成を自由に練り、再構成を行えるというのが、使っていて大きなポイントかもと思った。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`そんなこんなで、MindNodeを使って文章を書いているという話でした。`}</p>
      <p>{`ちなみに、このMindNodeで作るのは、一般的に「マインドマップ」として認識されているものに近いが、それとイコールとは言えないかもしれない。`}</p>
      <p>{`このマインドマップを広く知られるようにしたのは、Tony Buzanという人らしい。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Tony_Buzan",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Wikipedia: Tony Buzan`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Mind_map",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Wikipedia: Mind map`}</a></li>
      </ul>
      <p>{`自分はこれまでにマインドマップについては以下を読んだり見たりしたけれども、`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3nouvAF",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Amazon: Mind Mapping`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.udemy.com/course/the-secrets-of-effective-mind-mapping/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Mind Mapping Mastery`}</a></li>
      </ul>
      <p>{`元来のマインドマップは、色や紙面上の面積をどういう感じで使うかみたいなものも感じて考えたりしながら作るものらしく、それと比較すると、MindNodeはデジタルの良さを生かした、アウトライナー的な要素が強いものっぽい気がする。`}</p>
      <p>{`この記事を書いていて、むかし、Tony Buzan自身が話しているTEDの動画を見たけど、内容をさっぱり忘れてしまったので、改めて見直そうかと思った。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": ""
          }}>{`Youtube: The Power of a Mind to Map - Tony Buzan at TEDxSquareMile`}</a></li>
      </ul>
      <Youtube url="https://youtu.be/nMZCghZ1hB4" mdxType="Youtube" />
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      